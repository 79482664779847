import React, { useEffect, useState } from 'react';
import styles from './style/Dashboard.module.scss';
import Modal from '../Utilities/Modal';
import Table from '../Utilities/Table';
import Button from '../Utilities/Button';
import Paginate from '../Utilities/Paginate';
import { MdAdminPanelSettings, MdClear, MdManageAccounts } from 'react-icons/md';
import EmptyBox from '../Utilities/EmptyBox';
import { useDispatch, useSelector } from 'react-redux';
import { selectEnterpriseCompany } from '../../store/slice/manager/enterpriseCompany';
import LoadingTable from '../Utilities/LoadingTable';
import Tabs from '../Utilities/Tabs';
import InputSearch from '../Utilities/InputSearch';
import ToggleSwitch from '../Utilities/ToggleSwitch';
import { getEnterpriseUsers, selectEnterprise } from '../../store/slice/enterpriseLists';
import { BsCheckCircleFill } from 'react-icons/bs';

const ModalCreatePlan = ({ isOpen, setIsOpen, enterpriseId, setEnterpriseId }) => {
  const headerData = [
    {
      title: '姓名',
      width: 20,
    },
    {
      title: 'Email',
      width: 25,
    },
    {
      title: '加入時間',
      width: 15,
    },
    {
      title: '驗證狀態',
      width: 10,
      align: 'center',
    },
    {
      title: '帳號開通',
      width: 15,
    },
    {
      title: '官網 Admin',
      width: 10,
      align: 'center',
    },
    {
      title: 'Keystone Admin',
      width: 10,
      align: 'center',
    },
  ];

  const dispatch = useDispatch();
  const { loading } = useSelector(selectEnterpriseCompany);
  const { management_users } = useSelector(selectEnterprise);
  const [page, setPage] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const limit = 10;

  useEffect(() => {
    if (enterpriseId) {
      setPage(1);
      let postObj;
      if (tabValue > 0) {
        postObj = {
          enterprise_id: enterpriseId,
          page: 1,
          limit: limit,
          search: searchValue,
          enable: tabValue === 2 ? '1' : '0',
        };
      } else {
        postObj = {
          enterprise_id: enterpriseId,
          page: 1,
          limit: limit,
          search: searchValue,
        };
      }
      dispatch(getEnterpriseUsers(postObj));
    }
  }, [enterpriseId, dispatch, searchValue, tabValue]);

  const pageChangeHandler = (e) => {
    setPage(parseInt(e.selected + 1));
    let postObj;
    if (tabValue > 0) {
      postObj = {
        enterprise_id: enterpriseId,
        page: e.selected + 1,
        limit: limit,
        search: searchValue,
        enable: tabValue === 2 ? '1' : '0',
      };
    } else {
      postObj = {
        enterprise_id: enterpriseId,
        page: e.selected + 1,
        limit: limit,
        search: searchValue,
      };
    }
    dispatch(getEnterpriseUsers(postObj));
  };

  const [tabList] = useState(['全部', '未啟用', '已啟用']);

  return (
    <Modal isOpen={isOpen} size='xl'>
      <div className={styles.ModalContainer}>
        <div className={`${styles.modalHeader} ${styles.special}`}>
          <div className={styles.modalTitle}>使用者名單</div>
          <Button
            type='tertiary'
            icon={<MdClear />}
            onClick={() => {
              setIsOpen(false);
              setEnterpriseId('');
              setPage(1);
            }}
          />
        </div>

        <div className={`${styles.modalHeader} ${styles.special}`}>
          <div className={styles.modalWrapper}>
            <Tabs values={tabList} onChange={(e) => setTabValue(e.tabIndex)} selectedIndex={tabValue} />
          </div>

          <InputSearch
            placeholder='搜尋使用者姓名或Email'
            width={'200px'}
            value={search}
            onKeyPress={(e) => (e.code === 'Enter' || e.charCode === 13) && setSearchValue(search)}
            onChange={(e) => setSearch(e.target.value)}
            clickSearchIcon={() => {
              setSearchValue(search);
              setPage(1);
            }}
            onClear={() => {
              setSearch('');
              setSearchValue('');
            }}
          />
        </div>

        <div className={`${styles.modalBody} ${styles.nonOverflowPlus}`}>
          <Table header={headerData}>
            {loading ? (
              <LoadingTable row={10} column={8} />
            ) : (
              <>
                {management_users?.users?.length > 0 ? (
                  <tbody>
                    {management_users?.users?.map((data, index) => (
                      <tr key={index}>
                        <td width={`${headerData[0].width}%`} className={styles.textPrimary}>
                          {data.name}
                        </td>
                        <td width={`${headerData[1].width}%`} className={styles.textPrimary}>
                          {data.email}
                        </td>
                        <td width={`${headerData[2].width}%`}>{data.email_verified_at}</td>
                        <td width={`${headerData[3].width}%`} className={styles.textCenter}>
                          {data.email_verified_at && (
                            <div className={styles.checkIcon}>
                              <BsCheckCircleFill />
                            </div>
                          )}
                        </td>
                        <td width={`${headerData[4].width}%`}>
                          <ToggleSwitch isChecked={data.enable} disabled={true} small />
                        </td>
                        <td width={`${headerData[5].width}%`} className={styles.textPrimary} style={{ textAlign: 'center' }}>
                          {data.roles?.find((item) => item === 'cloud.admin') && <MdAdminPanelSettings style={{ fontSize: '22px' }} />}
                        </td>
                        <td width={`${headerData[6].width}%`} className={styles.textPrimary} style={{ textAlign: 'center' }}>
                          {data.roles?.find((item) => item === 'tdp.admin') && <MdManageAccounts style={{ fontSize: '22px' }} />}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody style={{ height: '450px' }}>
                    <tr>
                      <td colSpan={5} style={{ width: '100%', textAlign: 'center' }}>
                        <EmptyBox type={1} caption={'目前還未有使用者名單資料'} />
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            )}
          </Table>
        </div>

        {management_users?.users?.length > 0 && (
          <div className={`${styles.modalFooter} ${styles.normal}`}>
            <Paginate
              onPageChange={pageChangeHandler}
              pageCount={page}
              limit={limit}
              totalPages={Math.ceil(management_users?.users_count / limit)}
              totalData={management_users?.users_count}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalCreatePlan;
