import styles from './style/Alert.module.scss';
import Button from '../Button';
import { MdError } from 'react-icons/md';

const Alert = ({ mainTitle, subTitle, leftButtonText, rightButtonText, isOpen, onClose, onConfirm, isWarning, uploadFile, children }) => {
  return (
    <div className={isOpen ? styles.mask : styles.maskClose}>
      <div className={styles.alertContainer}>
        <div className={styles.customizeBar}>
          <div className={styles.iconContainer}>
            <MdError className={styles.icon} />
          </div>

          <div className={styles.content}>
            <h4>{mainTitle}</h4>
            {subTitle && <p>{subTitle}</p>}
            {children}
          </div>

          <div className={styles.buttonContainer}>
            {leftButtonText && (
              <div style={{ width: '100%' }}>
                <Button type='secondary' title={leftButtonText} onClick={onClose} color={'#666666'} />
              </div>
            )}
            {uploadFile ? (
              <div style={{ width: '100%' }}>
                <Button type='primary' title={rightButtonText} color={'#2E80C4'}>
                  <input type='file' id='uploadfile' onChange={onConfirm} accept='.xls, .xlsx' />
                </Button>
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                <Button type='primary' title={rightButtonText} onClick={onConfirm} color={isWarning ? '#F15C54' : '#2E80C4'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
