export const logOut = () => {
  let expDate = new Date();
  expDate.setTime(expDate.getTime() - 1);
  document.cookie = `token=; path=/; expires=${expDate.toUTCString()}`;
  localStorage.clear();
  window.location.href = '/';
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const handleExistEmailDomain = async (email) => {
  const index = email.lastIndexOf('@');
  const emailDomain = email.slice(index + 1);
  const response = await fetch(`https://dns.google/resolve?name=${emailDomain}`);
  const json = await response.json();
  return json.Status === 0;
};

export const passwordRegex =
  /^(?!.* )(?!.*[\u3105-\u3129\u02CA\u02C7\u02CB\u02D9\u4e00-\u9fa5\uff00-\uffff])((?=.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!\u0022#$%&'()*+,./:;<=>?@[\]^_`{|}~-]).*)/;

export const areaCodeRegex = /^[0,4]{1}\d{1,3}/;

export const telephoneRegex = /^[0-9]{3,4}[-\s.]?[0-9]{4}$/;

export const phoneNumberRegex = /^09\d{8}$/;

export const filterIdProducer = (year, quarter) => {
  if (quarter === '所有季度') return year;
  const quarterIdList = {
    Q1: '0101103',
    Q2: '0401103',
    Q3: '0701103',
    Q4: '1001103',
  };
  return `${year}${quarterIdList[`${quarter}`]}`;
};

export const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

// export const getPermission = (service) =>{
//   if(service === 'Keystone Core'){
//     return 'dtp'
//   }
// }

export const getQuarter = (d) => {
  d = d || new Date();
  var m = Math.floor(d.getMonth() / 3) + 1;
  return m > 4 ? m - 4 : m;
};

export const toChineseNumber = (n) => {
  if (!Number.isInteger(n) && n < 0) {
    throw Error('请输入自然数');
  }

  const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
  const charArray = String(n).split('');
  let result = '';
  let prevIsZero = false;
  //处理0  deal zero
  for (let i = 0; i < charArray.length; i++) {
    const ch = charArray[i];
    if (ch !== '0' && !prevIsZero) {
      result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
    } else if (ch === '0') {
      prevIsZero = true;
    } else if (ch !== '0' && prevIsZero) {
      result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
    }
  }
  //處理十 deal ten
  if (n < 100) {
    result = result.replace('一十', '十');
  }
  return result;
};

export const deepClone = (obj) => {
  if (typeof obj !== 'object' || obj == null) {
    return obj;
  }

  let result;

  if (obj instanceof Array) {
    result = [];
  } else {
    result = {};
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key]);
    }
  }
  return result;
};

export const getRandomCharNumSymbol = () => {
  // const symbols = '!@#$%^&*()_+[]{}|;:,.<>?';
  const symbols = '!@#$%&?';

  let choice = Math.floor(Math.random() * 4); // 0: uppercase, 1: lowercase, 2: number, 3: symbol

  let randomChar;
  switch (choice) {
    case 0: // Uppercase letter (A-Z)
      randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
      break;
    case 1: // Lowercase letter (a-z)
      randomChar = String.fromCharCode(97 + Math.floor(Math.random() * 26));
      break;
    case 2: // Number (0-9)
      randomChar = Math.floor(Math.random() * 10).toString();
      break;
    case 3: // Symbol
      randomChar = symbols[Math.floor(Math.random() * symbols.length)];
      break;
    default:
      randomChar = '#';
      break;
  }

  return randomChar;
};

export const generateRandomString = (length) => {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += getRandomCharNumSymbol();
  }
  return result;
};
