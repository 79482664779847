import styles from './style/layout.module.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronDownOutline } from 'react-icons/io5';
import { BiChevronLeft } from 'react-icons/bi';
import { selectEnterprise, getEnterpriseManagement } from '../../store/slice/enterpriseLists';
import { selectLeftNavigate } from '../../store/slice/navigate';
import { activateEnterpriseCompany, selectEnterpriseCompany } from '../../store/slice/manager/enterpriseCompany';

import Toast from '../Utilities/Toast';
import Button from '../Utilities/Button';
import AlertSwitch from '../Utilities/AlertSwitch';
import EmptyBox from '../Utilities/EmptyBox';
import Alert from '../Utilities/Alert';
import Tips from '../Utilities/Tips';
import ModalHistory from '../ExecutiveDashboard/ModalHistory';
import { RiHistoryLine } from 'react-icons/ri';

const ServiceListDetail = ({ enterprise, setEnterprise }) => {
  const [success, setSuccess] = useState(false);
  const [index, setIndex] = useState();
  const [service, setService] = useState('');
  const [serviceData, setServiceData] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState('');
  const [serviceId, setServiceId] = useState('');

  const { service_lists } = useSelector(selectEnterprise);
  const { isSuccess } = useSelector(selectEnterpriseCompany);
  const dispatch = useDispatch();

  useEffect(() => {
    if (enterprise || success) dispatch(getEnterpriseManagement({ enterprise_id: enterprise.enterprise_id }));
  }, [dispatch, enterprise, success]);

  const leftNavigate = useSelector(selectLeftNavigate);

  const handleListDetail = (i) => {
    setIndex(i);
    if (i === index && openDetail === true) setOpenDetail(false);
    else setOpenDetail(true);
  };

  const handleStartService = (data) => {
    const postObj = {
      enterprise_id: enterprise.enterprise_id,
      activation_setting: 1,
      service_id: data.software_services_id,
    };
    dispatch(activateEnterpriseCompany(postObj));
    setServiceData(data);
    setService('');
  };

  const handleStopService = () => {
    const postObj = {
      enterprise_id: enterprise.enterprise_id,
      activation_setting: 0,
      service_id: service.software_services_id,
    };
    dispatch(activateEnterpriseCompany(postObj));
    setServiceData(service);
    setService('');
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [setSuccess, isSuccess]);

  return (
    <>
      <ModalHistory
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        enterpriseId={enterpriseId}
        setEnterpriseId={setEnterpriseId}
        serviceId={serviceId}
        setServiceId={setServiceId}
      />

      <div
        className={
          enterprise && !leftNavigate.toggleOpen
            ? styles.ServiceListOpenNoInner
            : enterprise && leftNavigate.toggleOpen
            ? styles.ServiceListOpen
            : styles.ServiceListClose
        }
      >
        <Alert
          isOpen={service}
          onConfirm={handleStopService}
          onClose={() => setService('')}
          rightButtonText={'停用'}
          leftButtonText='取消'
          mainTitle={`停用「${service?.software_services_name}於KEYSTONE系統」`}
        />

        <div className={styles.dashboard}>
          <div className={styles.dashboardServiceList}>
            <div className={styles.headerContainer}>
              <div className={styles.dashboardTitle}>{enterprise?.enterprise_name}</div>
              <Button type='tertiary' title={'返回企業帳號系統管理'} icon={<BiChevronLeft />} onClick={() => setEnterprise('')} />
            </div>
            <div className={styles.dashboardListContainer}>
              <div className={styles.dashboardListTitle}>服務列表</div>
            </div>

            <Toast
              show={success}
              title={
                serviceData.activation_state === 0
                  ? `已成功開通「${serviceData.software_services_name}」`
                  : serviceData.activation_state === 1
                  ? `已成功停用「${serviceData.software_services_name}」`
                  : '目前僅能由企業自主開通'
              }
              subtitle={'艾普特媒體'}
            />

            <div className={styles.tableContainer}>
              <table>
                <thead>
                  <>
                    <tr>
                      <th>服務名稱</th>
                      <th>啟用日期</th>
                      <th>停用日期</th>
                      <th>購買人數</th>
                      <th>付款方式</th>
                      <th>開通狀態</th>
                      <th width='10%'></th>
                    </tr>
                  </>
                </thead>
                {service_lists.length > 0 ? (
                  <tbody>
                    {service_lists.map((data, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td className={styles.tableTd}>{data.software_services_name}</td>
                          <td className={styles.tableTd}>{data.start_at}</td>
                          <td className={styles.tableTd}>{data.end_at}</td>
                          <td className={styles.tableTd}>{data.users_count_upper_limit + data.additional_people} 人</td>
                          <td className={styles.tableTd}>{data.payment}</td>
                          <td className={styles.tableTd}>
                            <AlertSwitch
                              isChecked={data.activation_state}
                              onChange={() => (data.activation_state === 0 ? handleStartService(data) : setService(data))}
                            />
                          </td>
                          <td className={`${styles.dropLists} ${styles.tableTd}`}>
                            <div className={styles.actionWrapper}>
                              <Tips detail='查看訂閱購買紀錄'>
                                <Button
                                  type='pure'
                                  icon={<RiHistoryLine />}
                                  onClick={() => {
                                    setIsOpen(true);
                                    setServiceId(data.software_services_id);
                                    setEnterpriseId(enterprise.enterprise_id);
                                  }}
                                />
                              </Tips>

                              <div>
                                <Button
                                  type='pure'
                                  icon={<IoChevronDownOutline />}
                                  open={i === index && openDetail === true}
                                  onClick={() => handleListDetail(i)}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan='7'>
                            <div className={styles.details}>
                              {i === index && openDetail === true && (
                                <div>
                                  <div className={styles.wrapContainer}>
                                    <div className={`${styles.detailsListContainer} ${styles.half}`}>
                                      <div className={styles.detailsListTitle}>方案與日期</div>
                                      <div className={styles.detailsList}>
                                        {data.is_trial ? (
                                          <span className={styles.free}>Free</span>
                                        ) : (
                                          <>
                                            {data?.plan?.name === 'pro' ? (
                                              <span className={styles.pro}>{data?.plan?.name}</span>
                                            ) : (
                                              <span className={styles.basic}>{data?.plan?.name}</span>
                                            )}
                                          </>
                                        )}
                                        {data.start_date} ~ {data.expiration_date}
                                      </div>
                                    </div>
                                    <div className={`${styles.detailsListContainer} ${styles.half}`}>
                                      <div className={styles.detailsListTitle}>綁約天數</div>
                                      <div className={styles.detailsList}>{data.validity_days} 天</div>
                                    </div>
                                  </div>

                                  <div className={`${styles.wrapContainer} ${styles.flex}`}>
                                    <div className={`${styles.detailsListContainer} ${styles.half}`}>
                                      <div className={styles.detailsListTitle}>目前最高人數</div>
                                      <div className={styles.detailsList}>{data.users_count_upper_limit} 人</div>
                                    </div>
                                    <div className={`${styles.detailsListContainer} ${styles.half}`}>
                                      <div className={styles.detailsListTitle}>使用人數</div>
                                      <div className={styles.detailsList}>{data.users_number ? data.users_number : 0} 人</div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                ) : (
                  <tbody colSpan='7'>
                    <tr>
                      <td colSpan='7'>
                        <EmptyBox caption={'目前企業帳號還未訂閱任何服務'} />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceListDetail;
